import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

import './PlanModal.scss'

// Components
import { TitleDefault, ButtonDefault } from 'components/Elements'
import GravityForm from 'components/GravityForm'

const customStyles = {
  content: {
    width: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const SiteModal = ({ children }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }
  return (
    <div>
      <div className="video-btn mb-0">
        <div
          onClick={openModal}
          role="button"
          data-toggle="button"
          aria-pressed="false"
          className="button button-default cursor-pointer"
        >
          {children}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h3 className="mb-4 text-center ">
          Graag attenderen we u op ons Onderhoudsplan.
        </h3>
        <p>
          Wij bieden de mogelijkheid om u volledig te ontzorgen met ons
          Koelewijn-Crookewit meer jaren onderhoudsplan. Neem gerust contact met
          ons op voor meer informatie!
        </p>
      </Modal>
    </div>
  )
}

export default SiteModal
