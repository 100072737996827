import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

// Components
import { ButtonDefault } from 'components/Elements'
import Video from '../Video/Video'

import './VideoModal.scss'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const SiteModal = ({ children }) => {

  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }
  return (
    <div>
      <div className="text-center video-btn">
        <div
          onClick={openModal}
          role="button"
          className="button button-default"
        >
          {children}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Video
          videoSrcURL="https://player.vimeo.com/video/384476291"
          videoTitle="Koelewijn Crookewit"
        />
      </Modal>
    </div>
  )
}

export default SiteModal
